import dayjs from "dayjs";
import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { createContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Booking, Campaign, City, Client, Country, FireFilter, GrailDoc, List, ListAddCountsByMonth, Result, Tag, Talent, User } from '../shared/types';
import { analyticsService } from "./analytics";
import grailUtil from "./grailUtil";

import { QueryClient, QueryKey, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app(); // if already initialized, use that one
}

const firestore = firebase.firestore();
const storage = firebase.storage();
// const functions = firebase.functions();

// if (window.location.hostname === "localhost") {
//     firestore.useEmulator("localhost", 8080);
//     functions.useEmulator("localhost", 5001);
// }

// TO TEST A PUBSUB FUNCTION USING THE EMULATOR...
// TJN TO-DO: MAYBE... create a mini interface for this...
// const testPubSub = firebase.functions().httpsCallable('testPubSub');
// testPubSub({ topic: 'firebase-schedule-syncWithAirtable' });

export const UserContext = createContext<User | undefined>(undefined);
UserContext.displayName = "UserContext"; // https://reactjs.org/docs/context.html#contextdisplayname

export function useFirebaseAuth() {
    const [userAuthed, userLoading, userError] = useAuthState(firebase.auth());
    const [user, setUser] = useState<User>();

    useEffect(() => {
        if (!userLoading && userAuthed?.uid) {
            const unsubscribe = firestore.collection('users').doc(firebase.auth().currentUser?.uid).onSnapshot((doc) => {

                const userDoc = doc.data() as User;

                setUser({
                    ...userDoc,
                    id: firebase.auth().currentUser?.uid,
                });
            });

            // https://blog.logrocket.com/understanding-react-useeffect-cleanup-function/
            // https://benmcmahen.com/using-firebase-with-react-hooks/
            return () => unsubscribe()
        }
    }, [userLoading, userAuthed]);

    const signIn = async (email: string, password: string) => {
        try {
            const res = await firebase.auth().signInWithEmailAndPassword(email, password);

            if (res.user?.uid) {
                console.log("DOING IT!");
                await updateUserLastSignInTime(res.user?.uid);
            }

            analyticsService().logEvent("Logged In");
            analyticsService().setUserId(res.user!.uid);
            analyticsService().setUserProperty('email', email);

            return res;
        } catch (e) {
            analyticsService().logError(e);
            return e;
        }
    };

    const signUp = async (email: string, password: string) => {
        try {
            const res = await firebase.auth().createUserWithEmailAndPassword(email, password);

            const orgDomain = email.split('@')[1]?.toLowerCase() || null;
            const roles = orgDomain === 'grail-talent.com' ? {} : { "booker": true };

            if (res.additionalUserInfo?.isNewUser) {
                await addNewUserInfo(res.user!.uid, {
                    email: email,
                    orgDomain: orgDomain,
                    roles: roles,
                    ...(roles.booker && { bookerSelfRegistered: true }),
                });
            }

            analyticsService().logEvent("Signed Up");
            analyticsService().setUserId(res.user!.uid);
            analyticsService().setUserProperty('email', email);

            return res;
        } catch (e) {
            analyticsService().logError(e);
            return e;
        }
    }

    const resetPassword = async (email: string) => {
        try {
            let res = await firebase.auth().sendPasswordResetEmail(email);
            analyticsService().logEvent("Requested New Password", { email });
            return res;
        } catch (e) {
            analyticsService().logError(e);
            return e;
        }
    }

    const signOut = () => {
        firebase.auth().signOut();
        analyticsService().logEvent("Signed Out");
        analyticsService().logOut();

        setTimeout(function () {
            // reload the page after logout to properly handle drift...
            window.location.href = '/';
        }, 500);
    };

    return { user, userAuthed, userLoading, userError, signIn, signUp, signOut, resetPassword }; // isAdmin
}

function logDatabaseReads(functionName: string, docCount: number, filter?: FireFilter) {
    const databaseReadParams = {
        functionName: functionName,
        docCount: docCount,
        url: window.location.pathname,
        filter: filter,
    };

    if (window.location.hostname === "localhost" || (window as any).logGrail) {
        console.warn(`logDatabaseReads`, {
            ...databaseReadParams,
            filter: JSON.stringify(filter)
        });
    }

    analyticsService().logEvent('Queried Firestore', databaseReadParams);
}

////////////////////
// Firebase Queries:
////////////////////

export async function addNewUserInfo(uid: string, newUserInfo: any) {
    const userRef = firestore.collection("users").doc(uid);
    const res = await userRef.set({
        ...newUserInfo,
        docMeta: {
            ...newUserInfo.docMeta,
            updateTime: firebase.firestore.FieldValue.serverTimestamp(),
            lastKnownUpdateUser: firebase.auth().currentUser?.uid || null,
            lastKnownUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
        },
    }, { merge: true });

    console.log(`addNewUserInfo res`, res);
}

// TJN TO-DO: MAYBE - migrate to useFreshTalent()
export async function getTalentById(talentId: string) {
    const talentRef = firestore.collection('talent').doc(talentId);
    const talentData = (await talentRef.get()).data();
    logDatabaseReads('getTalentById', 1);

    return {
        ...talentData,
        id: talentId,
    } as Talent;
}

export async function getCountries() {
    const docRef = firestore.collection("lookups").doc("countries");
    let docSnap = await docRef.get();
    logDatabaseReads('getCountries', 1);

    if (docSnap.exists) {
        let doc: any = docSnap.data();

        // Alpha sort, but put US and UK at front, then return
        let countries = doc.countries.sort();
        countries = countries.filter((country: string) => country !== 'United Kingdom' && country !== 'United States');
        countries.unshift('United Kingdom');
        countries.unshift('United States');
        return countries;
    } else {
        return null;
    }
}

export async function getContentTags() {
    const docRef = firestore.collection("lookups").doc("contentTags");
    let docSnap = await docRef.get();
    logDatabaseReads('getContentTags', 1);

    if (docSnap.exists) {
        let doc: any = docSnap.data();
        return doc.tags.sort();
    } else {
        return null;
    }
}

// export async function getListById(listId: string) {
//     const listRef = firestore.collection('lists').doc(listId);
//     const listData = (await listRef.get()).data();
//     logDatabaseReads('getListById', 1);

//     return {
//         ...listData,
//         id: listId,
//     } as List;
// }

export function useListById(listId: string) {
    const [list, setList] = useState<List>();

    useEffect(() => {
        const listRef = firestore.collection('lists').doc(listId);
        const unsubscribe = listRef.onSnapshot((doc) => {
            const newListDoc = doc.data() as List;
            if (newListDoc) {
                setList(newListDoc);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [listId]);

    return list;
}

export async function getActiveLists() {
    const res = await firestore.collection("lists").where("active", "==", true).get();
    logDatabaseReads("getActiveLists", res.docs.length);

    return res.docs.map((doc) => doc.data() as List);
}

export async function createUser(userData: User) {
    let result: Result;

    try {
        // Create secondary app to avoid loging in and kickout out existing user...
        // https://stackoverflow.com/a/38013551/2972273
        const secondaryApp = firebase.initializeApp(firebaseConfig, `Secondary-${new Date().getTime().toString()}`);
        const authRes = await secondaryApp.auth().createUserWithEmailAndPassword(userData.email!, new Date().getTime().toString());

        await addNewUserInfo(authRes.user!.uid, {
            // email: email,
            ...userData,
            orgDomain: userData.email!.split('@')[1]?.toLowerCase() || null,
            docMeta: {
                createTime: firebase.firestore.FieldValue.serverTimestamp(),
                updateTime: firebase.firestore.FieldValue.serverTimestamp(),
                lastKnownUpdateUser: firebase.auth().currentUser?.uid || null,
                lastKnownUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
            }
        });

        result = { success: true, result: authRes.user!.uid };
        secondaryApp.delete().then(function () {
            console.log("DELETED APP");
        });

        return result;
    } catch (e) {
        result = { success: false, error: e };
        console.log(e);
        analyticsService().logError(e);
        return result;
    }
}

export async function createClient(clientData: Client) {
    let result: Result;

    clientData.docMeta = {
        updateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        lastKnownUpdateUser: firebase.auth().currentUser?.uid || null,
        lastKnownUpdateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    };

    // we always want new clients to get invoices at both emails by default
    clientData.invoiceBothBookerAndClient = true;

    try {
        const clientRef = firestore.collection('clients');
        const clientRes = await clientRef.add(clientData);

        return {
            success: true,
            result: clientRes.id
        }

    } catch (e) {
        result = { success: false, error: e };
        console.log(e);
        analyticsService().logError(e);
        return result;
    }
}

export async function createList(listData: Partial<List>): Promise<Result> {
    let result: Result;

    listData.docMeta = {
        updateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        lastKnownUpdateUser: firebase.auth().currentUser?.uid || null,
        lastKnownUpdateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    };

    try {
        const listsCollection = firestore.collection('lists');
        const res = await listsCollection.add(listData);

        result = {
            success: true,
            result: res.id
        };
        return result;

    } catch (e) {
        result = { success: false, error: e };
        console.log(e);
        analyticsService().logError(e);
        return result;
    }
}

export async function deleteList(listId: string): Promise<Result> {
    try {
        const listRef = firestore.collection('lists').doc(listId);
        const res = await listRef.delete();

        return {
            success: true,
            result: res,
        }
    } catch (e) {
        console.log(e);
        analyticsService().logError(e);
        return { success: false, error: e };
    }
}

// export async function updateListWithSafeDeletions(list: Partial<List>, deletedTalentIds?: string[]) {
//     try {
//         const listRef = firestore.collection('lists').doc(list.id);
//         const res = await listRef.set({
//             ...list,
//             talent: {
//                 ...list.talent,
//                 ...(deletedTalentIds && Object.fromEntries(deletedTalentIds.map((talId) => [talId, firebase.firestore.FieldValue.delete()])))
//             },
//             'docMeta.updateTime': firebase.firestore.FieldValue.serverTimestamp(),
//         }, { merge: true });

//         return {
//             success: true,
//             result: res,
//         }
//     } catch (e) {
//         console.log(e);
//         analyticsService().logError(e);
//         return { success: false, error: e };
//     }
// }

export async function updateList(list: Partial<List>) {
    try {
        const listRef = firestore.collection('lists').doc(list.id);

        // NOTE: use update here, so that we overwrite any known fields,
        // specifically the talent object, otherwise we'll preserve talent that has been removed
        const res = await listRef.set({
            ...list,
            docMeta: {
                updateTime: firebase.firestore.FieldValue.serverTimestamp(),
            },
        }, { merge: true });

        return {
            success: true,
            result: res,
        }
    } catch (e) {
        console.log(e);
        analyticsService().logError(e);
        return { success: false, error: e };
    }
}

export async function deleteTalentFromList(listId: string, talentId: string) {
    try {
        const listRef = firestore.collection('lists').doc(listId);
        const res = await listRef.update({
            [`talent.${talentId}`]: firebase.firestore.FieldValue.delete(),
            'docMeta.updateTime': firebase.firestore.FieldValue.serverTimestamp(),
        });

        return {
            success: true,
            result: res,
        }
    } catch (e) {
        console.log(e);
        analyticsService().logError(e);
        return { success: false, error: e };
    }
}

export async function incrementListAddHistory(props: { talentIds: string[], managerId: string, listAddKey: keyof ListAddCountsByMonth }): Promise<Result> {
    try {
        const { talentIds, managerId, listAddKey } = props;
        const monthKey = dayjs().format('YYYY-MM');
        const incrementPromises: Promise<unknown>[] = [];

        // Increment Manager Record by total number of new creators added
        const managerIncrementUpdate: Partial<User> = {
            listAddCountsByMonth: {
                [listAddKey]: {
                    [monthKey]: firebase.firestore.FieldValue.increment(talentIds.length),
                }
            }
        };
        const managerRef = firestore.collection("users").doc(managerId);
        const managerPromise = managerRef.set(managerIncrementUpdate, { merge: true });
        incrementPromises.push(managerPromise);

        // Increment Each Talent Record by 1
        const talentIncrementUpdate: Partial<User> = {
            listAddCountsByMonth: {
                [listAddKey]: {
                    [monthKey]: firebase.firestore.FieldValue.increment(1),
                }
            }
        };
        for (const talId of talentIds) {
            const talRef = firestore.collection("talent").doc(talId);
            const talPromise = talRef.set(talentIncrementUpdate, { merge: true });
            incrementPromises.push(talPromise);
        }

        // Wait for things to finish up
        await Promise.all(incrementPromises);
        return { success: true, result: null };
    } catch (e) {
        console.log(`incrementListAddHistory error`, e);
        return { success: false, error: e };
    }

}

export async function updateClient(clientData: Client) {
    let result: Result;

    try {
        const userRef = firestore.collection('clients').doc(clientData.id);
        const userRes = await userRef.set({
            ...clientData,
            docMeta: {
                updateTime: firebase.firestore.FieldValue.serverTimestamp(),
                lastKnownUpdateUser: firebase.auth().currentUser?.uid || null,
                lastKnownUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
            },
        }, { merge: true });

        result = { success: true, result: userRes };
        return result;
    } catch (e) {
        result = { success: false, error: e };
        console.log(e);
        analyticsService().logError(e);
        return result;
    }
}

export async function newCreateOrUpdateCampaign(campaign: Campaign, newBookings: Booking[], fileList?: FileList): Promise<Result> {
    try {
        const batch = firestore.batch();
        campaign.bookings = campaign.bookings || {};

        if (fileList) {
            const fileRes = await uploadFile(fileList);
            if (fileRes?.success) {
                campaign.fileUrl = fileRes.result;
            }
        }

        const docMeta = {
            updateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
            lastKnownUpdateUser: firebase.auth().currentUser?.uid || null,
            lastKnownUpdateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        };

        // NO LONGER TRUE: Create the new bookings first, so we can have IDs for the campaign bookings map...
        for (const booking of newBookings) {
            const newDocRef = firestore.collection("bookings").doc(booking.id);
            batch.set(newDocRef, { ...booking, docMeta: docMeta });
            campaign.bookings[newDocRef.id] = booking;
        }
        // campaign.bookings = newBookings; // TO-DO: maybe - rename to new-bookings

        // Then set the campaign (with merge)
        const campaignRef = firestore.collection("campaigns").doc(campaign.id);
        batch.set(campaignRef, { ...campaign, docMeta: docMeta }, { merge: true });

        await batch.commit();

        return {
            success: true,
            result: undefined,
        }
    } catch (e) {
        console.log(e);
        analyticsService().logError(e);

        return {
            success: false,
            error: e,
        }
    }
}

export async function updateUser(userData: User) {
    let result: Result;

    try {
        const userRef = firestore.collection('users').doc(userData.id); // firebase.auth().currentUser?.uid
        const userRes = await userRef.set({
            ...userData,
            // firstName: userData.firstName,
            // lastName: userData.lastName,
            // email: userData.email,
            phone: userData.phone || null,
            // company: userData.company,
            // currency: userData.currency,
            // docMeta: { updateTime: firebase.firestore.FieldValue.serverTimestamp() },
            // docMeta: { ...userData.docMeta, updateTime: firebase.firestore.FieldValue.serverTimestamp() },
            docMeta: {
                ...userData.docMeta,
                updateTime: firebase.firestore.FieldValue.serverTimestamp(),
                lastKnownUpdateUser: firebase.auth().currentUser?.uid || null,
                lastKnownUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
            },
        }, { merge: true });

        result = { success: true, result: userRes };
        return result;
    } catch (e) {
        result = { success: false, error: e };
        console.log(e);
        analyticsService().logError(e);
        return result;
    }
}

export async function updateTalent(talentData: User) {
    let result: Result;

    try {
        const talentRef = firestore.collection('talent').doc(talentData.id);
        const talentRes = await talentRef.set({
            ...talentData,
            // docMeta: { ...talentData.docMeta, updateTime: firebase.firestore.FieldValue.serverTimestamp() },
            docMeta: {
                ...talentData.docMeta,
                updateTime: firebase.firestore.FieldValue.serverTimestamp(),
                lastKnownUpdateUser: firebase.auth().currentUser?.uid || null,
                lastKnownUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
            },
        }, { merge: true });

        result = { success: true, result: talentRes };
        return result;
    } catch (e) {
        result = { success: false, error: e };
        console.log(e);
        analyticsService().logError(e);
        return result;
    }
}

export async function updateUserLastSignInTime(uid: string) {
    await updateUser({
        id: uid,
        docMeta: {
            lastSignInTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        }
    });
    return;
}

export async function getCampaignsByBookerId(uid: string, onlyRecent = true, onlyUninvoiced = false) {
    const res = await firestore.collection("campaigns").where("booker.uid", "==", uid).get();
    logDatabaseReads("getCampaignsByBookerId", res.docs.length);

    const campaigns = res.docs.map((doc) => doc.data() as Campaign);
    let filteredCampaigns = campaigns;

    if (onlyRecent) {
        const daysRecent = dayjs().subtract(45, 'days').toDate();
        filteredCampaigns = filteredCampaigns.filter((campaign) => !campaign.airtableDuplicate && (campaign.docMeta?.createTime && campaign.docMeta?.createTime.toDate() > daysRecent));
    }
    if (onlyUninvoiced) {
        filteredCampaigns = filteredCampaigns.filter((campaign) => !campaign.invoiceDate);
    }
    return filteredCampaigns;
}

// TJN TO-DO: MAYBE - migrate to useFresh... (more complicated)
export async function getUserById(uid: string): Promise<User> {
    const userRef = firestore.collection('users').doc(uid);
    let userData = (await userRef.get()).data();
    logDatabaseReads("getUserById", 1);

    return {
        ...userData,
        id: uid,
    };
}

// TJN TO-DO: MAYBE - migrate to useFreshClients()
export async function getClientById(clientId: string): Promise<Client> {
    const clientRef = firestore.collection('clients').doc(clientId);
    let clientData = (await clientRef.get()).data() as Client;
    logDatabaseReads("getClientById", 1);

    return {
        ...clientData,
        id: clientId,
    };
}

export async function getCampaignById(campaignId: string): Promise<Campaign> {
    const campaignRef = firestore.collection('campaigns').doc(campaignId);
    let campaignData = (await campaignRef.get()).data() as Campaign;
    logDatabaseReads("getCampaignById", 1);

    // try {

    // } catch(e) {

    // }

    return {
        ...campaignData,
        id: campaignId,
    };
}

export function generateNewDocId(collection: string) {
    const newDocRef = firestore.collection(collection).doc();
    return newDocRef.id;
}

// TJN TO-DO: MAYBE - migrate to getFreshManagers with mutation strategy?
export function useManagers2(apiRef: any) {
    const [managers, setManagers] = useState<User[]>([]); // , setManagers
    const [firstTime, setFirstTime] = useState(true);

    useEffect(() => {
        let unsubscribeManagers = () => { };
        let unsubscribeExternalManagers = () => { };

        const uniqueManagerIds: string[] = [];

        const managersQuery = firestore.collection("users").where("roles.manager", "in", [true, false]);
        const externalManagersQuery = firestore.collection("users").where("roles.externalManager", "in", [true, false]);

        // const newManagers: User[] = [];
        // const newExternalManagers: User[] = [];

        async function getManagers() {
            const managersSnapshot = await managersQuery.get();
            logDatabaseReads("useManagers2 - getManagers", managersSnapshot.docs.length);
            const newManagers = managersSnapshot.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            }) as User[];

            setManagers(newManagers);
            setFirstTime(false);
        }


        if (firstTime) {
            getManagers();
            setFirstTime(false);
        } else {
            unsubscribeManagers = managersQuery.onSnapshot((querySnapshot) => {
                const newManagers: User[] = [];

                let reads = 0;
                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === "added" || change.type === "modified") {
                        reads++;
                        const newDoc = {
                            id: change.doc.id,
                            ...change.doc.data()
                        };
                        newManagers.push(newDoc);
                        uniqueManagerIds.push(change.doc.id);
                    }
                });
                logDatabaseReads("useManagers2 - onSnapshot", reads);
                updateRows(newManagers);
            });

            unsubscribeExternalManagers = externalManagersQuery.onSnapshot((querySnapshot) => {
                const newManagers: User[] = [];
                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === "added" || change.type === "modified") {
                        const newDoc = {
                            id: change.doc.id,
                            ...change.doc.data()
                        };

                        // Only use do updates for those we're not already following in unsubscribeManagers, otherwise we'll get duplicate row errors
                        if (!uniqueManagerIds.find((id) => id === change.doc.id)) {
                            newManagers.push(newDoc);
                        }
                    }
                });
                updateRows(newManagers);
            });
        }

        function updateRows(newRows: User[]) {
            if (apiRef?.current?.updateRows) {
                apiRef.current?.updateRows(newRows);
            } else {
                console.log("NO apiRef.current.updateRows", apiRef.current);
            }
        }

        return () => {
            unsubscribeManagers();
            unsubscribeExternalManagers();
        };

    }, [apiRef, firstTime]);

    return managers;
}

export function useBookingsByCampaignId(campaignId?: string) {
    const [bookings, setBookings] = useState<Booking[]>([]);

    useEffect(() => {
        const bookingsQuery = firestore.collection("bookings").where("campaignId", "==", campaignId);
        const unsubscribe = bookingsQuery.onSnapshot((querySnapshot) => {
            const newBookings: Booking[] = [];

            querySnapshot.docChanges().forEach((change) => {
                newBookings.push({
                    id: change.doc.id,
                    ...change.doc.data()
                });

                setBookings(JSON.parse(JSON.stringify(newBookings)));
            });
        });

        return () => {
            unsubscribe();
        };

    }, [campaignId]);

    return bookings;
}

interface UseBookingsProps {
    managerId?: string,
    apiRef?: any,
    limitToManager?: boolean,
    limitToLast3Months?: boolean,
    bookingId?: string,
}



// https://stackoverflow.com/questions/26578167/es6-object-destructuring-default-parameters
export function useBookingsNew({ managerId = "", limitToManager = true, limitToLast3Months = true, apiRef = undefined } = {}) {

}

export function useBookings({ managerId = "", limitToManager = true, limitToLast3Months = true, apiRef = undefined, bookingId = undefined }: UseBookingsProps = {}) {
    // export function useBookings(managerId: string = "", apiRef: any, limitToManager: boolean = true, limitToLast3Months: boolean = true) {
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [firstTime, setFirstTime] = useState(true);
    const [queryCount, setQueryCount] = useState(0);
    // const [previousQuery, setPreviousQuery] = useState({ limitToManager, limitToLast3Months }); // , queryCount: queryCount

    // https://stackoverflow.com/a/61786423/2972273
    // Weird, but don't add the {} to the arrow function...
    // const debouncedSetBookings = useMemo(() =>
    //     grailUtil.debounce((newBookings: any) => {
    //         console.log("ACTUALLY UPDATING!", newBookings.length);

    //         // console.log(new Date());
    //         // setBookings(newBookings as Booking[]);
    //         apiRef.current.updateRows(newBookings);
    //     }, 100),
    //     [apiRef]
    // );

    useEffect(() => {
        let unsubscribe = () => { };
        // console.log("EFFECT!", limitToManager, managerId, limitToLast3Months, firstTime);

        let bookingsQuery:
            firebase.firestore.CollectionReference<firebase.firestore.DocumentData> |
            firebase.firestore.Query<firebase.firestore.DocumentData>
            = firestore.collection("bookings");

        if (bookingId) {
            bookingsQuery = bookingsQuery.where("id", "==", bookingId);
        } else {
            if (limitToManager) {
                bookingsQuery = bookingsQuery.where("managerIds", "array-contains", managerId);
            }
            if (limitToLast3Months) {
                bookingsQuery = bookingsQuery.where("monthDue2", '>', dayjs().subtract(3, "months").set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).toDate())
            }
        }

        async function getBookings() {
            const bookingsSnapshot = await bookingsQuery.get();
            logDatabaseReads("useBookings - getBookings", bookingsSnapshot.docs.length);
            const newBookings = bookingsSnapshot.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            }) as Booking[];

            setBookings(newBookings);
            setFirstTime(false);
        }

        if (firstTime || bookingId) {
            getBookings();
        }
        else {
            let snapCount = 0;

            unsubscribe = bookingsQuery.onSnapshot((querySnapshot) => {
                // if we've gotten more than 25 snapshots, and it's earlier than minute 10, delay...
                if (snapCount > 25 && parseInt(dayjs().format('m')) < 5) {
                    // let delayTime = new Date();
                    // console.log("DELAY", 2000, delayTime, querySnapshot.size, `snapCount:`, snapCount);
                    snapCount = 0;
                    unsubscribe();

                    setTimeout(() => {
                        // console.log(new Date(), "This is happening after the delay at", delayTime);
                        snapCount = 0;
                        setQueryCount(queryCount + 1);
                    }, 2000);
                } else {
                    // console.log("Actually writing", new Date(), querySnapshot.size, `snapCount:`, snapCount);
                    let newBookings: Booking[] = [];

                    let reads = 0;

                    querySnapshot.docChanges().forEach((change) => {
                        if (change.type === "added" || change.type === "modified") {
                            reads++;

                            newBookings.push({
                                id: change.doc.id,
                                ...change.doc.data()
                            });
                        }
                    });
                    logDatabaseReads("useBookings - onSnapshot", reads);

                    if (apiRef?.current?.updateRows) {
                        apiRef.current?.updateRows(newBookings);
                    } else {
                        console.log("NO apiRef.current.updateRows");
                    }


                }

                snapCount++;
            });

            // setPreviousQuery({ limitToManager, limitToLast3Months }); // , queryCount: queryCount
        }
        return () => {
            // console.log("UNSUBSCRIBE!");
            unsubscribe();
        };
    }, [apiRef, limitToManager, limitToLast3Months, managerId, queryCount, firstTime]);

    return { bookings, firstTime };
}



export async function updateDocField(collection: string, docId: string, fieldName: string, newVal: unknown) {
    let result: Result;

    // console.log(collection, docId, fieldName, newVal);

    try {
        const docRef = firestore.collection(collection).doc(docId);
        const res = await docRef.set({
            // [fieldName]: newVal || null,
            [fieldName]: (newVal === null || typeof newVal === 'undefined') ? null : newVal,
            docMeta: {
                updateTime: firebase.firestore.FieldValue.serverTimestamp(),
                lastKnownUpdateUser: firebase.auth().currentUser?.uid || null,
                lastKnownUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
            },
        }, { merge: true });

        result = { success: true, result: res };
        analyticsService().logEvent("Manager Updated Field", { collection, docId, fieldName, value: newVal });
        return result;
    } catch (e) {
        result = { success: false, error: e };
        console.log(e);
        analyticsService().logError(e);
        return result;
    }
}

export async function updateDoc(collection: string, docId: string, newVal: Object, note: string = "") {
    let result: Result;

    try {
        const docRef = firestore.collection(collection).doc(docId);
        const res = await docRef.set({
            // [fieldName]: newVal || null,
            ...newVal,
            docMeta: {
                updateTime: firebase.firestore.FieldValue.serverTimestamp(),
                lastKnownUpdateUser: firebase.auth().currentUser?.uid || null,
                lastKnownUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
            },
        }, { merge: true });

        result = { success: true, result: res };
        analyticsService().logEvent("Manager Updated Doc", { collection, docId, value: newVal, note });
        return result;
    } catch (e) {
        result = { success: false, error: e };
        console.log(e);
        analyticsService().logError(e);
        return result;
    }
}

// Seperate from updateDocField because of nesting...
export async function updateUserRole(userId: string, role: string, newVal: boolean) {
    let result: Result;

    console.log(userId, role, newVal);

    try {
        const docRef = firestore.collection("users").doc(userId);
        const res = await docRef.set({
            roles: {
                [role]: newVal || false,
            },
            docMeta: {
                updateTime: firebase.firestore.FieldValue.serverTimestamp(),
                lastKnownUpdateUser: firebase.auth().currentUser?.uid || null,
                lastKnownUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
            },
        }, { merge: true });

        result = { success: true, result: res };
        console.log(`result`, result);
        analyticsService().logEvent("Admin Updated Role", { userId, role, value: newVal });
    } catch (e) {
        result = { success: false, error: e };
        console.log(e);
        analyticsService().logError(e);
        return result;
    }
}

////////////////
// MISC:
////////////////
export async function acceptPaymentTerms(data: { firstName?: string, lastName?: string, userId?: string, clientId?: string }): Promise<Result> {
    const acceptPaymentTerms = firebase.functions().httpsCallable('acceptPaymentTerms');

    try {
        const res = await acceptPaymentTerms(data);
        return {
            success: true,
            result: res,
        }
    } catch (e) {
        console.log(e);
        return {
            success: false,
            error: e,
        }
    }
}

export async function sendNewClientForm(clientId: string, email: string): Promise<Result> {
    const sendNewClientForm = firebase.functions().httpsCallable('sendNewClientForm');

    try {
        const res = await sendNewClientForm({ clientId, email });
        return {
            success: true,
            result: res,
        }
    } catch (e) {
        console.log(e);
        return {
            success: false,
            error: e,
        }
    }
}

export async function uploadFile(fileList: FileList): Promise<Result> {
    try {
        if (fileList[0]) {
            const file = fileList[0];
            const storageRef = storage.ref();
            const fileRef = storageRef.child('campaigns/' + firebase.auth().currentUser?.uid + new Date().getTime().toString() + file.name);
            const uploadTaskSnapshot = await fileRef.put(file);
            const fileUrl = await uploadTaskSnapshot.ref.getDownloadURL();

            return {
                success: true,
                result: fileUrl,
            }
        } else {
            return {
                success: false,
                error: "FileList does not contain file..."
            }
        }
    } catch (e) {
        console.log(e);
        analyticsService().logError(e);
        return { success: false, error: e };
    }
}



////////////////
// React Query:
////////////////
export function useExchangeRate() {
    // console.log(`useExchangeRate!`);
    const query = useQuery({
        queryKey: ['exchangeRate'],
        queryFn: () => grailUtil.getExchangeRate(),
        staleTime: 1000 * 60 * 10 // 10 minutes
    });

    return query.data || 1.3;
}

export function useFreshBookers() {
    const queryKey = ['bookers'];
    const collectionName = 'users';
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKey,
        queryFn: async () => getFreshCollection<User>(queryKey, queryClient, collectionName, {
            key: 'roles.booker',
            operator: "==",
            value: true
        }),
        staleTime: Infinity,
        refetchOnMount: 'always',
        onError: (err) => handleFreshQueryError(err, queryKey)
    });

    return { flatData: query.data || [], query };
}

export function useFreshCampaigns(limitToLast3Months: boolean) {
    const queryKey = ['campaigns', limitToLast3Months];
    const collectionName = 'campaigns';
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKey,
        queryFn: async () => {
            if (limitToLast3Months) {
                return getFreshCollection<Campaign>(queryKey, queryClient, collectionName, {
                    key: "docMeta.updateTime", // NOTE... don't use createTime, because you can't have two inequality operators
                    operator: '>',
                    // NOTE - Setting to 5 months because we may have bookings that were dragging on
                    // e.g. if campaign/booking were created 4 months ago, but booking is updated since then and campaign isn't
                    value: dayjs().subtract(5, "months").set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).toDate()
                })
            }
            return getFreshCollection<Campaign>(queryKey, queryClient, collectionName);
        },
        staleTime: Infinity,
        refetchOnMount: 'always',
        onError: (err) => handleFreshQueryError(err, queryKey)
    });

    return { flatData: query.data || [], query };
}

export function useFreshClients() {
    const queryKey = ['clients'];
    const collectionName = 'clients';
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKey,
        queryFn: async () => getFreshCollection<Client>(queryKey, queryClient, collectionName),
        staleTime: Infinity,
        refetchOnMount: 'always',
        onError: (err) => handleFreshQueryError(err, queryKey)
    });

    return { flatData: query.data || [], query };
}

export function useFreshCountries() {
    const queryKey = ['countries'];
    const collectionName = 'countries';
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKey,
        queryFn: async () => getFreshCollection<Country>(queryKey, queryClient, collectionName),
        staleTime: Infinity,
        refetchOnMount: 'always',
        onError: (err) => handleFreshQueryError(err, queryKey)
    });

    return { flatData: query.data || [], query };
}

// TJN TO-DO: Lists -- figure out why it's not caching when listId is provided...
export function useFreshLists({ listId, uid, creatorRequest }: { listId?: string, uid?: string, creatorRequest?: boolean }) {
    const queryKey = ['lists', listId, uid, creatorRequest];
    const collectionName = 'lists';
    const queryClient = useQueryClient();

    let filter: FireFilter | FireFilter[];

    if (listId) {
        filter = {
            key: "id",
            operator: "==",
            value: listId
        };
    } else if (uid) {
        filter = {
            key: "uid",
            operator: "==",
            value: uid
        };
    } else if (creatorRequest) {
        filter = {
            key: "creatorRequest",
            operator: "==",
            value: creatorRequest
        }
    } else {
        filter = [{
            key: "active",
            operator: "==",
            value: true
        }, {
            key: "uid",
            operator: "==",
            value: "system"
        }];
    }

    const query = useQuery({
        queryKey: queryKey,
        queryFn: async () => getFreshCollection<List>(queryKey, queryClient, collectionName, filter),
        staleTime: Infinity,
        refetchOnMount: 'always',
        onError: (err) => handleFreshQueryError(err, queryKey)
    });

    return { flatData: query.data || [], query };
}

export function useFreshManagers() { // TJN TO-DO: MAYBE - includeExternal = false
    const queryKey = ['managers'];
    const collectionName = 'users';
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKey,
        queryFn: async () => {
            return Promise.all([
                getFreshCollection<User>(queryKey, queryClient, collectionName, { key: "roles.manager", operator: "==", value: true }),
                getFreshCollection<User>(queryKey, queryClient, collectionName, { key: "roles.externalManager", operator: "==", value: true }),
            ]).then((managerRes) => {
                const allManagers = managerRes.flat(1);
                const uniqueManagers = [...new Map(allManagers.map((manager) =>
                    [manager['email'], manager])).values()];
                return uniqueManagers;
            });
        },
        staleTime: Infinity,
        refetchOnMount: 'always',
        onError: (err) => handleFreshQueryError(err, queryKey)
    });

    return { flatData: query.data || [], query };
}

export function useFreshTags() {
    const queryKey = ['tags'];
    const collectionName = 'tags';
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKey,
        queryFn: async () => getFreshCollection<Tag>(queryKey, queryClient, collectionName),
        staleTime: Infinity,
        refetchOnMount: 'always',
        onError: (err) => handleFreshQueryError(err, queryKey)
    });

    return { flatData: query.data || [], query };
}

export function useFreshCities() {
    const queryKey = ['cities'];
    const collectionName = 'cities';
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKey,
        queryFn: async () => getFreshCollection<City>(queryKey, queryClient, collectionName),
        staleTime: Infinity,
        refetchOnMount: 'always',
        onError: (err) => handleFreshQueryError(err, queryKey)
    });

    return { flatData: query.data || [], query };
}

export function useFreshTalent(requestByManager = false, includeDeleted = false) {
    const queryKey = ['talent'];
    const collectionName = 'talent';
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKey,
        queryFn: async () => getFreshCollection<Talent>(queryKey, queryClient, collectionName),
        staleTime: Infinity,
        refetchOnMount: 'always',
        onError: (err) => handleFreshQueryError(err, queryKey)
    });

    // Value returned from useFresh functions must be memoized (query.data is, but if filtering it's not anymore)!
    const flatData = useMemo(() => {
        return (query.data || []).filter((tal: Talent) => {
            const notRecentlySynced = grailUtil.compareDates(tal.lastSynced!, new Date(), 'day') < -14;
            const showForTesting = window.location.hostname === "localhost" && tal.tiktokUser === 'grailtest1';

            return (!tal.hideUser && !tal.deleted && !notRecentlySynced)
                || showForTesting
                || (requestByManager && !tal.deleted)
                || includeDeleted;
        }).sort((tal1: Talent, tal2: Talent) => {
            return (tal2.ttFollowers || 0) - (tal1.ttFollowers || 0);
        });
    }, [query.data, includeDeleted, requestByManager]);

    // console.log(`flatData.length`, flatData.length);
    return { ...query, flatData };
}

///////////////////////////////////
// React Query 'Fresh' Generics v2:
///////////////////////////////////
function isCompoundFilter(filter: FireFilter | FireFilter[]): filter is FireFilter[] {
    return (filter as FireFilter[]).length !== undefined;
}

async function getFreshCollection<T extends GrailDoc>(queryKey: QueryKey, queryClient: QueryClient, collectionName: string, filter?: FireFilter | FireFilter[]) {
    const oldData: GrailDoc[] = queryClient.getQueryData(queryKey) || [];
    const updatedSince = findLargestTimestamp(oldData);

    // console.log(queryKey, collectionName, filter);

    let firebaseQuery: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | firebase.firestore.Query<firebase.firestore.DocumentData>
        = firestore.collection(collectionName);
    if (updatedSince) {
        firebaseQuery = firebaseQuery.where("docMeta.updateTime", ">", updatedSince);
    }

    let filterKey: string | undefined = undefined;
    if (filter && isCompoundFilter(filter)) {
        for (const fil of filter) {
            firebaseQuery = firebaseQuery.where(fil.key, fil.operator, fil.value);
        }
        filterKey = filter[0].key;
    } else if (filter) {
        firebaseQuery = firebaseQuery.where(filter.key, filter.operator, filter.value);
        filterKey = filter.key;
    }

    const snapshot = await firebaseQuery.get();
    logDatabaseReads(`getFreshCollection - ${collectionName}, ${filterKey}`, snapshot.docs.length, { key: 'docMeta.updateTime', operator: '>', value: updatedSince });

    const newData = snapshot.docs.map((doc) => {
        return {
            id: doc.id,
            ...doc.data()
        } as T
    });
    // console.log(`newData.length`, queryKey, newData.length);
    return flattenData<T>(oldData, newData);
}

function findLargestTimestamp(data: GrailDoc[]) {
    // This is exact, and handles conversion from JSON...
    const times = data?.map((item) => item?.docMeta?.updateTime ? grailUtil.convertJsonToFirestoreTimestamp(item.docMeta.updateTime).toDate() : new Date('0'))
    if (data.length) {
        const max = Math.max(...times as any);
        return new Date(max);
    }
    return undefined;
}

function flattenData<T>(oldData: GrailDoc[], newData: GrailDoc[]) {
    const combinedData: GrailDoc[] = [];
    if (oldData) {
        combinedData.push(...oldData);
    }
    for (const item of newData) {
        const idx: number = combinedData.findIndex((t) => t.id === item.id);
        if (idx >= 0) {
            combinedData[idx] = item;
        } else {
            combinedData.push(item);
        }
    }

    return combinedData as T[];
}

function handleFreshQueryError(err: unknown, queryKey: QueryKey) {
    console.warn(`Error in useFresh Query ::: ${(queryKey[0] as string).toLocaleUpperCase()}`, err);
}