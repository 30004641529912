import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFreshManagers, useFreshTalent } from '../services/firebase'; // getUsersByRole,
import { Talent, User } from '../shared/types';

import Layout from '../components/Layout';
import TalentFilter, { defaultFilter } from "../components/TalentFilter";

export default function Manager() {
    const { uid } = useParams<{ uid: string }>();
    const [filter, setFilter] = useState({ ...defaultFilter, rosterMode: true, managerShowHiddenAndDeleted: false });
    const managersQuery = useFreshManagers();
    const managers = managersQuery.flatData;

    let manager: User | undefined;
    if (uid) {
        manager = managers.find((man) => man.id === uid);
    }

    const talentQuery = useFreshTalent(true, true);
    const talent = (uid ? talentQuery.flatData.filter((tal) => tal.managerIds?.includes(uid)) : talentQuery.flatData)
        .sort((talA: Talent, talB: Talent) => (talA.tiktokUser || talA.instagramUser || "") > (talB.tiktokUser || talB.instagramUser || "") ? 1 : -1);

    return (
        <div className="RosterPage">
            <Layout gray={true}>
                <div className="container mt-300 mb-300">
                    {manager ? (
                        <>
                            <h1 className="mb-100">{manager?.firstName ? `${manager?.firstName} ${manager.lastName}` : manager?.email || uid}'s Roster:</h1>
                        </>
                    ) : null}

                    <TalentFilter
                        talent={talent}
                        filter={filter}
                        rosterMode={true}
                        updateFilter={(filterPartial) => setFilter({ ...filter, ...filterPartial })}
                    />
                </div>
            </Layout>
        </div>
    );
}